import constant from "../../constant";
import axios from "axios";
import { printData } from "../../utils/printUtils"; 
import router from "../../router";
const api = constant.api + "payments";
// initial state
const state = () => ({
  all: [],
  payments: [],
  payment: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getPayment: (state) => { 
    return state.payment;
  },

  getPayments: (state) => {
    return state.payments; 
  },

  getTotalAmount: (state) =>
    state.payments.reduce((acc, val) => acc + parseFloat(val.amount), 0),
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let payments = response.data;
      commit("SET_PAYMENTS", payments);
      window.location;
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let payment = response.data;
      commit("setPayment", payment);
    });
  }, 

  async store({ commit, dispatch }, data) {
    try {
      // Show loading notification
      dispatch(
        "notification/loading",
        {
          message: "Veuillez patienter...",
        },
        { root: true }
      );

      // Attempt to send data with Axios
      const response = await axios.post(api, data);
      const { message, status } = response.data;

      // Show success or error notification based on response
      dispatch("notification/store", { status, message }, { root: true });

      // Redirect if status is success
      if (status === "success") {
        router.push({ name: "payments-index" });
      }
    } catch (error) {
      // Handle error notification if request fails
      dispatch(
        "notification/store",
        { status: "error", message: "Une erreur est survenue." },
        { root: true }
      );
    } finally {
      // Clear loading message
      dispatch(
        "notification/loading",
        {
          message: "",
        },
        { root: true }
      );
    }
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "payments-index",
        });
      }
    });
  },

  async destroy({ dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;

      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "payments-index",
        });
      }
    });
  },

  async filter({ dispatch, commit }, data) {
    await axios.post(api + "_filter", data).then((response) => {
      let payments = response.data;
      commit("SET_PAYMENTS", payments);
    });
  },

  async print({ dispatch }, data) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "La liste des paiements du client est en cours de téléchargement. Veuillez patienter...",
        },
        { root: true }
      );
      const response = await axios
        .post(api + "_print", data, {
          responseType: "blob", // Set the response type to 'blob' to handle binary data
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Paiements.pdf");
          link.click();
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  },

  async printReceipt({ dispatch }, reference) {
    try {
      dispatch( 
        "notification/storeDownloading",
        {
          status: "success",
          message:
            "Le recu est en téléchargement, Veuillez patienter...",
        },
        { root: true }
      );

      //  const apiBaseUrl = this.getters["api/getApiBaseUrl"];
      await printData(null, `${api}_print_receipt`, "GET", reference);
    } catch (error) {
      console.error(error);
    }
  },

 
};

// mutations
const mutations = {
  SET_PAYMENTS(state, payments) {
    state.all = payments;
    state.payments = payments;
  },
  setPayment(state, payment) {
    state.payment = payment;
  },
 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
