<template>
  <div class="position-absolute w-100">
    <div class="d-flex justify-content-center overflow-x-auto py-xl-0 py-3">
      <div
        v-for="(navbar, index) in filteredNavbarData"
        :key="index++"
        class="w-auto "
      >
        <router-link
          :to="{ name: navbar.name }"
          :class="$route.name.includes(navbar.slug) ? 'active' : ''"
          class="s-navbar__nav-link py-xl-1 px-xl-2"
        >
          <i :class="`${navbar.icon}`"></i>
          <div>
            {{ navbar.title }}
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import navbar from "../../data/navbar/index.json";
export default {
  data() {
    return {
      settings_display: false,
      security_display: false,
      isSidebarOpen: false,
      navbarData: navbar,
    };
  },
  methods: {},
  mounted() {},
  computed: {
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),

    filteredNavbarData() {
      if (this.authData.role != null) {
        if (this.authData.role.fullName != null) {
          if (this.authData.role.fullName == "Admin")
            return this.navbarData.filter((p) => p.is_admin == true);

          if (this.authData.role.fullName == "Caissier")
            return this.navbarData.filter((p) => p.is_cashier == true);

          if (this.authData.role.fullName == "Magasinier")
            return this.navbarData.filter((p) => p.is_storekeeper == true);

            if (this.authData.role.fullName == "Agent d'atelier automobile")
            return this.navbarData.filter((p) => p.is_car_workshop_agent == true);
        }
      }

      // return this.items.filter(
      //   (item) => item.section_reference == this.section_reference
      // );
    },
  },
};
</script>
<style scoped>
.active {
  border-bottom: 1px solid #ffffff;
  border-top: none;
  font-weight: 400 !important;
  background: #bfc9dd !important;
  /* color: #f4f4f4 !important; */
  border-radius: 3px !important;
}

.bi {
  font-size: 14px !important;
  margin: 0;
}
.bi-logo {
  font-size: 23px !important;
  margin: 0;
}
@media (max-width: 767px) {
  .bi {
    font-size: 32px !important;
    margin: 0;
  }
}
</style>
