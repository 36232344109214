import constant from "../../constant";
import axios from "axios";

const api = constant.api + "dashboard";

const state = {
  nextReservation: {},
  nextReservations: [],
  activeFields: [],
  dashboardTotals: {},
  nextPayments: null,
  historicalPayments: [],
  monthlyPayments: [], // New state for monthly payments
};

const getters = {
  getNextReservation: (state) => state.nextReservation,
  getNextReservations: (state) => state.nextReservations,
  getActiveFields: (state) => state.activeFields,
  getDashboardTotals: (state) => state.dashboardTotals,
  getNextPayments: (state) => state.nextPayments,
  getHistoricalPayments: (state) => state.historicalPayments,
  getMonthlyPayments: (state) => state.monthlyPayments, // New getter for monthly payments
};

const actions = {
  async getDashboardTotals({ commit }) {
    await axios.get(api + "_totals").then((response) => {
      let reservation = response.data;
      commit("SET_DASHBOARD_TOTALS", reservation);
    });
  },

  async getNextReservation({ commit }) {
    await axios.get(api + "_next").then((response) => {
      let reservation = response.data;
      commit("SET_NEXT_RESERVATION", reservation);
    });
  },

  async getNextReservations({ commit }) {
    await axios.get(api + "_next_all").then((response) => {
      let reservations = response.data;
      console.log(reservations);
      commit("SET_NEXT_RESERVATIONS", reservations);
    });
  },

  async getActiveFields({ commit }) {
    await axios.get(api + "_active").then((response) => {
      let activeFields = response.data;
      commit("SET_ACTIVE_FIELDS", activeFields);
    });
  },

  async archiveOldBookings({ dispatch }) {
    await axios.get(api + "_archive_old").then((response) => {
      let status = response.data.status;
      let message = response.data.message;
      dispatch("notification/store", { status, message }, { root: true });
    });
  },

  async getNextPayments({ commit }) {
    await axios.get(api + "_next").then((response) => {
      commit("SET_NEXT_PAYMENTS", response.data);
    });
  },

  async getHistPaymentsByDate({ commit }) {
    await axios.get(api + "_payments_hist").then((response) => {
      commit("SET_HISTORICAL_PAYMENTS", response.data);
    });
  },

  async getPaymentsByMonth({ commit }) {
    // Fetch monthly payments from the API
    await axios.get(api + "_payments_monthly").then((response) => {
      commit("SET_MONTHLY_PAYMENTS", response.data);
    });
  },
};

const mutations = {
  SET_NEXT_RESERVATION(state, reservations) {
    state.nextReservation = reservations;
  },
  SET_NEXT_RESERVATIONS(state, reservations) {
    state.nextReservations = reservations;
  },
  SET_ACTIVE_FIELDS(state, data) {
    state.activeFields = data;
  },
  SET_DASHBOARD_TOTALS(state, reservations) {
    state.dashboardTotals = reservations;
  },
  SET_NEXT_PAYMENTS(state, data) {
    state.nextPayments = data;
  },
  SET_HISTORICAL_PAYMENTS(state, data) {
    state.historicalPayments = data;
  },
  SET_MONTHLY_PAYMENTS(state, data) {
    state.monthlyPayments = data; // Update state with monthly payments
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
