import constant from "../../constant";
import axios from "axios";
import router from "../../router";
import { printData } from "../../utils/printUtils";
const api = constant.api + "fields";
// initial state
const state = () => ({
  all: [],
  customers: [],
  customer_types: [],
  customer: {},
  customer_type: {},
  
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getCustomer: (state) => {
    return state.customer;
  },

  getCustomers: (state) => {
    return state.customers;
  },

  getCustomerType: (state) => {
    return state.customer_type;
  },

  getCustomerTypes: (state) => {
    return state.customer_types;
  },
  
};

// actions
const actions = {
 
  async getAll({ commit }) { 
    await axios.get(api).then((response) => {
      let customers = response.data;
      commit("setCustomers", customers);
    });
  },

  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let customer = response.data;
      commit("setCustomer", customer);
    });
  },

  async store({ dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "customers-index",
        });
      }
    });
  },

  async printRegistration({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        { 
          status: "success",
          message:
            "La fiche de renseignement est en téléchargement, Veuillez patienter...",
        },
        { root: true }
      );

      //  const apiBaseUrl = this.getters["api/getApiBaseUrl"];
      await printData([], `${api}_print_registration`, "GET", reference);
    } catch (error) {
      console.error(error);
    }
  },

  

  async update({ commit, dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "customers-index",
        });
      }
    });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let customers = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("setCustomers", customers);
    });
  },

};

// mutations
const mutations = {
  setCustomers(state, customers) {
    state.all = customers;
    state.customers = customers;
  },
  setCustomer(state, customer) {
    state.customer = customer;
  },

  setCustomerTypes(state, customer_types) {
    // state.all = customer_types;
    state.customer_types = customer_types;
  },
  setCustomerType(state, customer_type) {
    state.customer_type = customer_type;
  },
 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
