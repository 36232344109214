// store/modules/discussion.js
import constant from '../../constant'
import axios from "axios";
const api = constant.api + 'discussions'
const state = {
  discussions: [],
}

const getters = {
  getDiscussions: (state) => state.discussions,
}

const actions = {
  async fetch({ commit }) {
    try {
      const response = await axios.get(`${api}`) // Use this.$axios
      commit('SET_DISCUSSIONS', response.data)
    } catch (error) {
      console.error('Error fetching discussions:', error)
    }
  },

  async store({ commit }, data) {
    try {
      await axios
        .post(`${api}`, data) // Use this.$axios
        .then((response) => {
          commit('SET_DISCUSSIONS', response.data)
        })
    } catch (error) {
      console.error('Error storing discussions:', error)
    }
  },
}

const mutations = {
  SET_DISCUSSIONS: (state, discussions) => (state.discussions = discussions),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
