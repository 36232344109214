import constant from "../../constant";
import axios from "axios";
import router from "../../router";
import { printData } from "../../utils/printUtils"; 
const api = constant.api + "subscriptions";
// initial state
const state = () => ({
  all: [],
  subscriptions: [],
  subscription: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getSubscription: (state) => {
    return state.subscription;
  },

  getSubscriptions: (state) => {
    return state.subscriptions;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let subscriptions = response.data;
      commit("SET_SUBSCRIPTIONS", subscriptions);
    });
  },

  async getCustomerUnpaid({ commit }, reference) {
    await axios.get(api + "_unpaid/" + reference).then((response) => {
      let subscriptions = response.data;
      commit("SET_SUBSCRIPTIONS", subscriptions);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let subscription = response.data;
      commit("SET_SUBSCRIPTION", subscription);
    });
  },

  async getActiveSubscription({ commit }, reference) {
    await axios
      .get(api + "_active_subscription/" + reference)
      .then((response) => {
        let subscription = response.data;
        commit("SET_SUBSCRIPTION", subscription);
      });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "subscriptions-index" });
    });
  },

  async validate({ commit, dispatch }, data) {
    await axios.post(api + "_validate", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "subscriptions-index" });
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "subscriptions-index",
        });
      }
    });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let subscriptions = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("SET_SUBSCRIPTIONS", subscriptions);
    });
  },

  async print({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            " Veuillez patienter...",
        },
        { root: true }
      );

      //  const apiBaseUrl = this.getters["api/getApiBaseUrl"];
      await printData([], `${api}_print_page`, "GET", reference);
    } catch (error) {
      console.error(error);
    }
  },

  async printAll({ dispatch }) {
    try {
      dispatch(
        "notification/storeDownloading",
        {
          status: "success",
          message:
            " Veuillez patienter...",
        },
        { root: true }
      );

      //  const apiBaseUrl = this.getters["api/getApiBaseUrl"];
      await printData(null, `${api}_print_all`, "GET");
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  SET_SUBSCRIPTIONS(state, subscriptions) {
    state.all = subscriptions;
    state.subscriptions = subscriptions;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.subscription = subscription;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
