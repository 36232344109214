import constant from "../../constant";
import axios from "axios";
import router from "../../router";
import { printData } from "../../utils/printUtils";
const api = constant.api + "bookings";
// initial state
const state = () => ({
  all: [],
  reservations: [],
  nextReservations: [],
  reservation: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getReservation: (state) => {
    return state.reservation;
  },

  getReservations: (state) => {
    return state.reservations;
  },
};

// actions
const actions = { 
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let reservations = response.data;
      commit("SET_RESERVATIONS", reservations);
    });
  },

  async filter({ commit },data) {
    await axios.post(api+"_filter",data).then((response) => {
      let reservations = response.data;
      commit("SET_RESERVATIONS", reservations);
    });
  },

  async archiveOldBookings({ dispatch }) {
    await axios.get(api + "_archive_old").then((response) => {
      let status = response.data.status;
      let message = response.data.message;
      dispatch("notification/store", { status, message }, { root: true });
    });
  },

  async getCustomerUnpaid({ commit }, reference) {
    await axios.get(api + "_unpaid/" + reference).then((response) => {
      let reservations = response.data;
      commit("SET_RESERVATIONS", reservations);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let reservation = response.data;
      commit("SET_RESERVATION", reservation);
    }); 
  },

  async createMatche({ commit }, data) {
    await axios.post(api + "add_matche", data).then((response) => {
      let reservation = response.data;
      commit("SET_RESERVATION", reservation);
    });
  },

  async store({ commit, dispatch }, data) {
    try {
      // Show loading notification
      dispatch(
        "notification/loading",
        {
          message: "Veuillez patienter...",
        },
        { root: true }
      );

      // Attempt to send data with Axios
      const response = await axios.post(api, data);
      const { message, status } = response.data;

      // Show success or error notification based on response
      dispatch("notification/store", { status, message }, { root: true });

      // Optional: Redirect if status is success
      if (status === "success") {
        router.push({ name: "reservations-index" });
      }
    } catch (error) {
      // Handle error notification if request fails
      dispatch(
        "notification/store",
        { status: "error", message: "Une erreur est survenue." },
        { root: true }
      );
    } finally {
      // Clear loading message
      dispatch(
        "notification/loading",
        {
          message: "",
        },
        { root: true }
      );
    }
  },

  async storeProfessional({ commit, dispatch }, data) {
    try {
      // Show loading notification
      dispatch(
        "notification/loading",
        {
          message: "Veuillez patienter...",
        },
        { root: true }
      );

      // Attempt to send data with Axios
      const response = await axios.post(api + "_professionals", data);
      const { message, status } = response.data;

      // Show success or error notification based on response
      dispatch("notification/store", { status, message }, { root: true });

      // Optional: Redirect if status is success
      if (status === "success") {
        router.push({ name: "reservations-index" });
      }
    } catch (error) {
      // Handle error notification if request fails
      dispatch(
        "notification/store",
        { status: "error", message: "Une erreur est survenue." },
        { root: true }
      );
    } finally {
      // Clear loading message
      dispatch(
        "notification/loading",
        {
          message: "",
        },
        { root: true }
      );
    }
  },

  async validate({ commit, dispatch }, data) {
    await axios.post(api + "_validate", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "reservations-index" });
    });
  },

  async update({ dispatch }, data) {
    try {
      // Show loading notification
      dispatch(
        "notification/loading",
        {
          message: "Veuillez patienter...",
        },
        { root: true }
      );

      // Attempt to send data with Axios
      const response = await axios.put(api + "/" + data.reference, data);
      const { message, status } = response.data;

      // Show success or error notification based on response
      dispatch("notification/store", { status, message }, { root: true });

      // Redirect if the status is success
      if (status === "success") {
        router.push({
          name: "reservations-index",
        });
      }
    } catch (error) {
      // Handle error notification if request fails
      dispatch(
        "notification/store",
        { status: "error", message: "Une erreur est survenue." },
        { root: true }
      );
    } finally {
      // Clear loading message
      dispatch(
        "notification/loading",
        {
          message: "",
        },
        { root: true }
      );
    }
  },
  
  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let reservations = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "reservations-index" });
    });
  },

  async filter({ commit, dispatch }, data) {
    try {
      // Show loading notification
      dispatch(
        "notification/loading",
        {
          message: "Veuillez patienter...",
        },
        { root: true }
      );

      // Attempt to send data with Axios
      const response = await axios.post(`${api}_filter`, data);
      const { message, status } = response.data;

      // Show success or error notification based on response
      dispatch("notification/store", { status, message }, { root: true });

      let reservations = response.data;
      commit("SET_RESERVATIONS", reservations);
      
    } catch (error) {
      // Handle error notification if request fails
      dispatch(
        "notification/store",
        { status: "error", message: "Une erreur est survenue." },
        { root: true }
      );
    } finally {
      // Clear loading message
      dispatch(
        "notification/loading",
        {
          message: "",
        },
        { root: true }
      );
    }
  },

  // async print({ dispatch }, reference) {
  //   try {
  //     dispatch(
  //       "notification/storeDownloading",
  //       { status: "success", message: "downloading..." },
  //       { root: true }
  //     );
  //     const response = await axios
  //       .get(api + "_print_page/" + reference, {
  //         responseType: "blob", // Set the response type to 'blob' to handle binary data
  //       })
  //       .then((response) => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", "Facture.pdf");
  //         link.click();
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },

  async print({ dispatch }, reference) {
    try {
      dispatch(
        "notification/storeDownloading",
        { 
          status: "success",
          message:
            "La fiche de reservation est en téléchargement, Veuillez patienter...",
        },
        { root: true }
      );

      //  const apiBaseUrl = this.getters["api/getApiBaseUrl"];
      await printData([], `${api}_print_page`, "GET", reference);
    } catch (error) {
      console.error(error);
    }
  },
};

// mutations
const mutations = {
  SET_RESERVATIONS(state, reservations) {
    state.reservations = reservations;
  },

  SET_RESERVATION(state, reservation) {
    state.reservation = reservation;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
