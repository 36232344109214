import axios from "axios";

export const printData = async (data, api, method, params = null) => {
  try {
    // Construct API URL
    let apiUrl = api;
    if (method.toUpperCase() === "GET" && params) {
      apiUrl += `/${params}`;
    }

    const config = {
      responseType: "json",
    };

    if (method.toUpperCase() === "GET") {
      config.params = {};
    } else {
      config.data = data;
    }

    const response = await axios[method.toLowerCase()](apiUrl, config);

    // Decode the Base64 PDF output
    const binaryData = atob(response.data.output);

    // Convert the decoded string into a Uint8Array (byte array)
    const bytes = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      bytes[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the byte array
    const blob = new Blob([bytes], { type: "application/pdf" });

    // Create a URL for the Blob and trigger the download
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", response.data.filename); // Use filename from the response
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};
