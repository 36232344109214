import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import academy from "./modules/academy";
import discussion from "./modules/discussion";
import reservation from "./modules/reservation";
import pricing from "./modules/pricing";
import auth from "./modules/auth";
import backup from "./modules/backup";
import customer from "./modules/customer";
import expense from "./modules/expense";
import matche from "./modules/matche";
import media from "./modules/media";
import message from "./modules/message";
import notification from "./modules/notification";
import partner from "./modules/partner";
import payment from "./modules/payment";
import transaction from "./modules/transaction";
import troubleshooting from "./modules/troubleshooting";
import terrain from "./modules/terrain";
import user from "./modules/user";
import subscription from "./modules/subscription";
import dashboard from "./modules/dashboard";
export default new Vuex.Store({
  state: {},
  getters: {
    userRole: (state, getters) => getters["auth/getUserRole"],
  },
  mutations: {},
  actions: {},
  modules: {
    academy,
    discussion,
    reservation,
    pricing,
    auth,
    backup,
    customer,
    matche,
    media,
    message,
    notification,
    partner,
    payment,
    transaction,
    troubleshooting,
    expense,
    terrain,
    user,
    subscription,
    dashboard,
  },
});
