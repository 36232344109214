import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // redirect: { name: "dashboard" },
    name: "dashboard",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Dashboard",
      layout: "dashboard",
    },
    component: () => import("../views/Dashboard.vue"),
    beforeEach: (to, from, next) => {
      next({ name: "dashboard" });
    },
  },
  {
    path: "*",
    name: "404",
    meta: {
      title: "404",
      layout: "dashboard",
    },
    component: () => import("../views/errors/404.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Dashboard",
      layout: "dashboard",
    },
    component: () => import("../views/Dashboard.vue"),
  },

  {
    path: "/test",
    name: "test",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Test",
      layout: "dashboard",
    },
    component: () => import("../views/Test.vue"),
  },
  //scan
  {
    path: "/troubleshooting",
    name: "troubleshooting",
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Scan",
      layout: "dashboard",
    },
    component: () => import("../layouts/index/TroubleshootingIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "",
          layout: "dashboard",
        },
        path: "/",
        name: "troubleshooting-index",
        component: () => import("../views/troubleshootings/ScanPage.vue"),
      },
    ],
  },

  //about
  {
    path: "/about",
    name: "about",
    meta: {
      is_auth: false,
      title: "About",
      layout: "default",
    },
    component: () => import("../views/About.vue"),
  },
  //login
  {
    path: "/login",
    name: "login",
    meta: {
      is_auth: false,
      title: "Login",
      layout: "login",
    },
    component: () => import("../views/auth/Login.vue"),
  },

  //login-redirect
  {
    path: "/login/redirect",
    name: "login-redirect",
    meta: {
      is_auth: false,
      title: "Login",
      layout: "login",
    },
    component: () => import("../views/auth/Redirect.vue"),
  },

  // mobile router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Mobile",
      layout: "dashboard",
    },
    path: "/mobile",
    name: "mobile",
    component: () => import("../layouts/index/MobileIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Mobile Home",
          layout: "dashboard",
        },
        path: "home",
        name: "mobile-home-index",
        component: () => import("../views/mobiles/Home.vue"),
      },
    ],
  },

  // subscriptions router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "subscriptions",
      layout: "dashboard",
    },
    path: "/subscriptions",
    name: "subscriptions",
    component: () => import("../layouts/index/SubscriptionIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "subscriptions-index",
        component: () => import("../views/subscriptions/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: ":reference/create",
        name: "subscriptions-create",
        component: () => import("../views/subscriptions/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "subscriptions-edit",
        component: () => import("../views/subscriptions/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "subscriptions-details",
        component: () => import("../views/subscriptions/Details.vue"),
      },
    ],
  },

  // pricings router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "pricings",
      layout: "dashboard",
    },
    path: "/pricings",
    name: "pricings",
    component: () => import("../layouts/index/PricingIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "pricings-index",
        component: () => import("../views/pricings/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "create",
        name: "pricings-create",
        component: () => import("../views/pricings/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "pricings-edit",
        component: () => import("../views/pricings/Edit.vue"),
      },
    ],
  },

  // partners router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "partners",
      layout: "dashboard",
    },
    path: "/partners",
    name: "partners",
    component: () => import("../layouts/index/PartnerIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "partners-index",
        component: () => import("../views/partners/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "create",
        name: "partners-create",
        component: () => import("../views/partners/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "partners-edit",
        component: () => import("../views/partners/Edit.vue"),
      },
    ],
  },

  // medias router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "medias",
      layout: "dashboard",
    },
    path: "/medias",
    name: "medias",
    component: () => import("../layouts/index/MediaIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "medias-index",
        component: () => import("../views/medias/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "create",
        name: "medias-create",
        component: () => import("../views/medias/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "medias-edit",
        component: () => import("../views/medias/Edit.vue"),
      },
    ],
  },

  // matches router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "matches",
      layout: "dashboard",
    },
    path: "/matches",
    name: "matches",
    component: () => import("../layouts/index/MatcheIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "matches-index",
        component: () => import("../views/matches/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: ":reference/create",
        name: "matches-create",
        component: () => import("../views/matches/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "matches-edit",
        component: () => import("../views/matches/Edit.vue"),
      },
    ],
  },

  // terrains router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "terrains",
      layout: "dashboard",
    },
    path: "/terrains",
    name: "terrains",
    component: () => import("../layouts/index/TerrainIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "terrains-index",
        component: () => import("../views/terrains/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "create",
        name: "terrains-create",
        component: () => import("../views/terrains/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "terrains-edit",
        component: () => import("../views/terrains/Edit.vue"),
      },
    ],
  },

  // customers router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "customers",
      layout: "dashboard",
    },
    path: "/customers",
    name: "customers",
    component: () => import("../layouts/index/CustomerIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "customers-index",
        component: () => import("../views/customers/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "create",
        name: "customers-create",
        component: () => import("../views/customers/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "customers-edit",
        component: () => import("../views/customers/Edit.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "customers-details",
        component: () => import("../views/customers/Details.vue"),
      },
    ],
  },

  // reservations router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "reservations",
      layout: "dashboard",
    },
    path: "/reservations",
    name: "reservations",
    component: () => import("../layouts/index/ReservationIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "reservations-index",
        component: () => import("../views/reservations/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "create",
        name: "reservations-create",
        component: () => import("../views/reservations/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: "professionals/create",
        name: "reservations-professionals-create",
        component: () =>
          import("../views/reservations/professionals/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "reservations-edit",
        component: () => import("../views/reservations/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier un produit",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "reservations-details",
        component: () => import("../views/reservations/Details.vue"),
      },
    ],
  },

  //user roles router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "user_roles",
      layout: "dashboard",
    },
    path: "/user",
    name: "users",
    component: () => import("../layouts/index/UsersIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Roles",
          layout: "dashboard",
        },
        path: "roles/index",
        name: "users-roles-index",
        component: () => import("../views/user_roles/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un role",
          layout: "dashboard",
        },
        path: "roles/create",
        name: "users-roles-create",
        component: () => import("../views/user_roles/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le role",
          layout: "dashboard",
        },
        path: ":reference/roles/create",
        name: "users-roles-edit",
        component: () => import("../views/user_roles/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Utilisateurs d'application",
          layout: "dashboard",
        },
        path: "index",
        name: "users-index",
        component: () => import("../views/users/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un nouvel utilisateur",
          layout: "dashboard",
        },
        path: "create",
        name: "users-create",
        component: () => import("../views/users/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Creer un produit",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "users-edit",
        component: () => import("../views/users/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Authentications d'utilisateurs d'application",
          layout: "dashboard",
        },
        path: "authentications",
        name: "authentications-index",
        component: () => import("../views/users/Authentications.vue"),
      },
    ],
  },

  // payments router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Paiements",
      layout: "dashboard",
    },
    path: "/payments",
    name: "payments",
    component: () => import("../layouts/index/PaymentIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste des paiements des clients",
          layout: "dashboard",
        },
        path: "index",
        name: "payments-index",
        component: () => import("../views/payments/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un nouveau paiement pour le client",
          layout: "dashboard",
        },
        path: ":reference/create",
        name: "payments-create",
        component: () => import("../views/payments/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le paiment",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "payments-edit",
        component: () => import("../views/payments/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails d'un paiement",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "payments-details",
        component: () => import("../views/payments/Details.vue"),
      },
    ],
  },

  // transactions router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Paiements",
      layout: "dashboard",
    },
    path: "/transactions",
    name: "transactions",
    component: () => import("../layouts/index/TransactionIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste des paiements des clients",
          layout: "dashboard",
        },
        path: "customers/index",
        name: "transactions-index",
        component: () => import("../views/transactions/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un nouveau paiement pour le client",
          layout: "dashboard",
        },
        path: "customers/create",
        name: "transactions-create",
        component: () => import("../views/transactions/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le paiment",
          layout: "dashboard",
        },
        path: "customers/:reference/edit",
        name: "transactions-edit",
        component: () => import("../views/transactions/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails d'un paiement",
          layout: "dashboard",
        },
        path: "customers/:reference/details",
        name: "transactions-details",
        component: () => import("../views/transactions/Details.vue"),
      },
    ],
  },

  //  expenses  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "expenses",
      layout: "dashboard",
    },
    path: "/expenses",
    name: "expenses",
    component: () => import("../layouts/index/ExpenseIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Dépenses de société",
          layout: "dashboard",
        },
        path: "index",
        name: "expenses-index",
        component: () => import("../views/expenses/Listing.vue"),
      },
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Effectué et enregistre l'achat",
          layout: "dashboard",
        },
        path: "create",
        name: "expenses-create",
        component: () => import("../views/expenses/Create.vue"),
      },
    ],
  },

  // academies router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "academies",
      layout: "dashboard",
    },
    path: "/academies",
    name: "academies",
    component: () => import("../layouts/index/AcademyIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste d'articles",
          layout: "dashboard",
        },
        path: "index",
        name: "academies-index",
        component: () => import("../views/academies/Listing.vue"),
      },
    ],
  },
  //  backups  router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "backups",
      layout: "dashboard",
    },
    path: "/backups",
    name: "backups",
    component: () => import("../layouts/index/BackupIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Backup",
          layout: "dashboard",
        },
        path: "index",
        name: "backups-index",
        component: () => import("../views/backups/Listing.vue"),
      },
    ],
  },

  // discussions router
  {
    meta: {
      is_auth: true,
      is_admin: true,
      title: "Paiements",
      layout: "dashboard",
    },
    path: "/discussions",
    name: "discussions",
    component: () => import("../layouts/index/DiscussionIndex.vue"),
    children: [
      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Liste des paiements des clients",
          layout: "dashboard",
        },
        path: "index",
        name: "discussions-index",
        component: () => import("../views/discussions/Listing.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Créer un nouveau paiement pour le client",
          layout: "dashboard",
        },
        path: ":reference/create",
        name: "discussions-create",
        component: () => import("../views/discussions/Create.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Modifier le paiment",
          layout: "dashboard",
        },
        path: ":reference/edit",
        name: "discussions-edit",
        component: () => import("../views/discussions/Edit.vue"),
      },

      {
        meta: {
          is_auth: true,
          is_admin: true,
          title: "Détails d'un paiement",
          layout: "dashboard",
        },
        path: ":reference/details",
        name: "discussions-details",
        component: () => import("../views/discussions/Details.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function guard(to, from, next, authData, userRole) {
  // console.log(authData);
  if (to.meta && to.meta.is_auth == true) {
    if (authData != null && authData.reference != undefined) {
      if (to.meta.is_admin == true) {
        if (userRole != null) {
          if (userRole == "Admin") {
            return next();
          } else if (userRole == "Magasinier") {
            return next();
            // return next({ name: "storekeepe-unauthorized" });
          } else if (userRole == "Caissier") {
            return next();
            // return next({ name: "cashier-unauthorized" });
          }
        }
      } else {
        /*if (userRole != null) {
          if (to.meta.is_storekeeper == true) {
            if (userRole == "Admin") {
              return next();
            } else if (userRole == "Magasinier") {
              return next();
            } else if (userRole == "Caissier") {
              return next({ name: "cashier-unauthorized" });
            }
          } else if (to.meta.is_cashier == true) {
            if (userRole == "Admin") {
              return next();
            } else if (userRole == "Caissier") {
              return next();
            } else if (userRole == "Magasinier") {
              return next({ name: "storekeepe-unauthorized" });
            }
          } else if (to.meta.is_viewer == true) {
            if (userRole == "Admin") {
              return next();
            } else if (userRole == "Observateur") {
              return next();
            } else if (userRole == "Caissier") {
              // return next();
            } else if (userRole == "Magasinier") {
              // return next({ name: "storekeepe-unauthorized" });
            }
          } else if (to.meta.is_ecommerce_admin == true) {
            if (userRole == "Admin") {
              return next();
            } else if (userRole == "Ecommerce Admin") {
              return next();
            } else if (userRole == "Caissier") {
              // return next();
            } else if (userRole == "Magasinier") {
              // return next({ name: "storekeepe-unauthorized" });
            }
          }
        }*/
      }
    }
  }
  if (to.name == "login") {
    console.log("route login");
    return next();
  }
  next();
}

router.beforeEach((to, from, next) => {
  let authData = store.getters["auth/getAuthData"];
  let userRole = store.getters["auth/getUserRole"];
  return guard(to, from, next, authData, userRole);
});

export default router;
