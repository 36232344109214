import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";



import "./app.css";
import "./axios-interceptor"; // Import the interceptor file

import DashboardAdmin from "./layouts/DashboardAdmin.vue";
import Default from "./layouts/Default.vue";
import Login from "./layouts/Login.vue";

import DataTable from "./components/cards/DataTable.vue";

Vue.prototype.$userRole = store.getters.userRole;

import { toFixedWithSpace } from "./filters";
Vue.prototype.$toFixedWithSpace = toFixedWithSpace;
Vue.filter("toFixedWithSpace", toFixedWithSpace);

Vue.config.productionTip = false;

Vue.component("vue-data-table", DataTable);
Vue.component("layout-dashboard", DashboardAdmin);
Vue.component("layout-default", Default);
Vue.component("layout-login", Login);

Vue.component("vueInternetChecker", require("vue-internet-checker"));

window.axios = require("axios");
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const token = localStorage.getItem("token-psm-goodgroupe-app-1");
axios.defaults.headers.common["Authorization"] =
  "Bearer " + String(token).replaceAll('"', "");



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
