<template>
  <div class="row d-flex align-items-center m-0 p-0">
    <div class="col-xl-auto col-lg-auto col-md-auto col-auto">
      <span>
        
        <i
          class="bi bi-logo "
        >
        <img src="/logo.svg" height="35"  alt="">
      </i> 
      </span>
    </div>
    

    <div
      class="col-xl col-lg col-md col-4 d-xl-flex d-lg-flex d-md-flex d-none"
    >
     
      <NewsTickerVue />
    </div>

    <div
      class="col-xl-auto col-lg-auto col-md-auto col justify-content-end bprder border-primary d-flex align-items-center"
    >
      
      <button
        @click="
          logout_display ? (logout_display = false) : (logout_display = true)
        "
        class="btn d-flex align-items-center px-1 py-2 text-warning rounded"
      >
        <span>
          <i class="bi bi-person-circle fs-3"></i>
        </span>
        <span class="">
          {{ authData.name }}
        </span>
      </button>
      <span class="d-xl-inline d-lg-inline d-md-inline d-none">
        <ChatBotVue />
      </span>
    </div>

    <div class="col-xl-auto col-lg-auto col-md-auto col-12">
      <AlertVue class="border"></AlertVue>
    </div>

    <div
      v-if="logout_display"
      :style="{
        width: '200px',
        height: '100px',
        position: 'absolute',
        top: '4.3em',
        right: '10px',
        zIndex: 1000,
      }"
      class="position-absolute p-3 bg-white rounded shadow align-items-end"
    >
      <button @click="logout()" class="btn btn-danger col-auto px-3 py-2">
        Deconnecter
      </button>
    </div>
  </div>
</template>
<script>
import NewsTickerVue from "../cards/NewsTicker.vue";
import AlertVue from "../../views/notification/Alert.vue";
import ChatBotVue from "../cards/ChatBot.vue";
import SearchVue from "../cards/Search.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    NewsTickerVue,
    AlertVue,
    ChatBotVue,
    SearchVue,
  },
  data() {
    return {
      display_sidebar: false,
      logout_display: false,
    };
  },
  computed: {
    ...mapGetters("auth", {
      authData: "getAuthData",
    }),
  },
  mounted() {
    // this.getWindowWidth();
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      // this.$router.push({ name: "login" });
    },

    getWindowWidth() {
      const pageWidth = document.documentElement.clientWidth;
      console.log("Page Width (Vue):", pageWidth, "pixels");
      return pageWidth;
      // You can store this value in a data property if needed.
    },
  },
};
</script>
