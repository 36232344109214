import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "pricings";
// initial state
const state = () => ({
  all: [],
  pricings: [],
  pricing: {},
});

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getPricing: (state) => {
    return state.pricing;
  },

  getPricings: (state) => {
    return state.pricings;
  },
};

// actions
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let pricings = response.data;
      commit("SET_PRICINGS", pricings);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let pricing = response.data;
      commit("SET_PRICING", pricing);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "pricings-index" });
    });
  },

  async validate({ commit, dispatch }, data) {
    await axios.post(api + "_validate", data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "pricings-index" });
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "pricings-index",
        });
      }
    }); 
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let pricings = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("SET_PRICINGS", pricings);
    });
  },
};

// mutations
const mutations = {
  SET_PRICINGS(state, pricings) {
    state.all = pricings;
    state.pricings = pricings;
  },
  SET_PRICING(state, pricing) {
    state.pricing = pricing;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
