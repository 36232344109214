import constant from "../../constant";
import axios from "axios";
import router from "../../router";
const api = constant.api + "matches";
// initial state
const state = () => ({
  all: [],
  matches: [],
  matche: {},
}); 

// getters
const getters = {
  getAll: (state) => {
    return state.all;
  },
  getMatche: (state) => {
    return state.matche;
  },

  getMatches: (state) => {
    return state.matches;
  },
};

// actions 
const actions = {
  async getAll({ commit }) {
    await axios.get(api).then((response) => {
      let matches = response.data;
      commit("SET_MATCHES", matches);
    });
  },
  async show({ commit }, reference) {
    await axios.get(api + "/" + reference).then((response) => {
      let matche = response.data;
      commit("SET_MATCHE", matche);
    });
  },

  async store({ commit, dispatch }, data) {
    await axios.post(api, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") router.push({ name: "matches-index" });
    });
  },

  async update({ dispatch }, data) {
    await axios.put(api + "/" + data.reference, data).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      dispatch("notification/store", { status, message }, { root: true });

      if (status == "success") {
        router.push({
          name: "matches-index",
        });
      }
    });
  },

  async toggleMatcheStatus({ commit,dispatch }, data) {
    await axios
      .put(api + "_status/" + data.reference, data)
      .then((response) => {
        let message = response.data.message;
        let status = response.data.status;
        dispatch("notification/store", { status, message }, { root: true });

        if (status == "success") {
          let matches = response.data.data;
          commit("SET_MATCHES", matches);
        }
      });
  },

  async destroy({ commit, dispatch }, reference) {
    await axios.delete(api + "/" + reference).then((response) => {
      let message = response.data.message;
      let status = response.data.status;
      let matches = response.data.data;
      dispatch("notification/store", { status, message }, { root: true });
      if (status == "success") commit("SET_MATCHES", matches);
    });
  },
};

// mutations
const mutations = {
  SET_MATCHES(state, matches) {
    state.all = matches;
    state.matches = matches;
  },
  SET_MATCHE(state, matche) {
    state.matche = matche;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
