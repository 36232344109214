const state = () => ({
  alert: {
    message: "",
    // message: "Rien à signaler, vous pouvez continuer.",
    errorMessages: [],
  },
  downloading: { 
    status: false,
    message: "",
  },
  loading: {
    message: "",
  },
});

const getters = {
  getAlert: (state) => {
    return state.alert;
  },

  getDownloading: (state) => state.downloading,

  getLoading: (state) => state.loading,
};

const actions = {
  async store({ commit }, data) {
    // this._vm.$notify("text");

    commit("setAlert", data);
    setTimeout(function () {
      commit("setAlert", {
        message: "",
        // message: "Rien à signaler, vous pouvez continuer.",
        errorMessages: [],
      });
    }, 10000);
  },

  async storeDownloading({ commit }, data) {
    commit("SET_LOADING", data);
    setTimeout(function () {
      commit("SET_LOADING", {
        message: "",
        // message: "Rien à signaler, vous pouvez continuer.",
      });
    }, 5000);
  },

  async loading({ commit }, data) {
    commit("SET_LOADING", data);
  },
};

const mutations = {
  async setAlert(state, data) {
    await (state.alert = data);
  },

  async SET_LOADING(state, data) {
    await (state.loading = data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
